
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {useGroupStore} from "@/store/group.store";
import {PinaLoadEntity} from "@/core/composite/composite";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import VerticalEntity from "@/components/common/VerticalEntity.vue";
import store from "@/store";
import {getCurrentCompany} from "@/core/services/JwtService";
import Row from "@/components/base/common/Row.vue";
// import introJs from 'intro.js';
// import 'intro.js/introjs.css';


export default defineComponent({
  name: "Company",
  components: {Row, VerticalEntity, TabNavigate,},

  setup() {
    const groupStore = useGroupStore();
    const submitting = ref(false);
    const companies = computed(() => store.getters.currentUser.companies);

    const selectedCompany = (companies) => {
      const id = getCurrentCompany();
      return companies.value?.find(item => item.id === id)?.name
    }
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Company', [
        {link: false, router: '', text: selectedCompany(companies)},
      ]);
/*
      introJs().setOptions({
        steps: [
          {
            title: 'Welcome',
            intro: "Welcome to Company Settings!"
          },
          {
            element: '#settings',
            intro: "Update company name and other settings<br>upload logo <br>and manage subscription"
          },
          {
            element: '#users',
            intro: "Invite and manage your team members"
          },
          {
            element: '#teams',
            intro: "Organize users in teams for a better management and permissions"
          },
          {
            element: '#spaces',
            intro: "Manage workspaces and setup permissions"
          },
          {
            element: '#actions',
            intro: "View all actions performed by users"
          },
          {
            element: '#logins',
            intro: "View all login sessions"
          },
          {
            element: '#permissions',
            intro: "Manage company permissions"
          }
        ]
      }).start();
*/
    })

    const company = computed(() => groupStore.entity);
    return {
      submitting,
      company,
      ...PinaLoadEntity(groupStore),
    }
  },
  methods: {}
})
